<template>
  <b-overlay :show="isLoading">
    <b-row v-if="$route.params.langId">
      <b-col cols="12">
        <b-card>
          <div>
            <h1>افزودن نوشته جدید</h1>
            <span v-if="!isNewPage"
              >مشاهده خبر در زبان
              {{ $route.query.lang == "انگلیسی" ? "فارسی" : "انگلیسی" }}:
            </span>
            <a v-if="!isNewPage" :href="mainPageLink" target="_blank">
              {{ $route.query.title }}
            </a>
          </div>
          <hr class="mb-3" />
          <b-row>
            <b-col cols="12">
              <b-form @submit.prevent>
                <b-form-group label="عنوان نوشته" label-for="title">
                  <b-form-input
                    :state="titleState"
                    id="title"
                    v-model="blogDetails.title"
                    trim
                    placeholder="عنوان نوشته را وارد کنید"
                  />
                  <b-form-invalid-feedback>
                    <div
                      class="w-100 d-flex align-items-center justify-content-between"
                    >
                      <p>طول عنوان نباید بیشتر از 200 کاراکتر باشد.</p>
                      <template
                        v-if="blogDetails.title && blogDetails.title.length > 0"
                      >
                        {{ blogDetails.title.length }}/200
                      </template>
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-form>
            </b-col>
            <b-col cols="4" class="my-1">
              <b-form @submit.prevent>
                <b-form-group label="وضعیت انتشار نوشته" label-for="blogStatus">
                  <b-form-select
                    id="blogStatus"
                    v-model="blogDetails.status"
                    :options="blogStatusEnums"
                  ></b-form-select>
                </b-form-group>
              </b-form>
            </b-col>
            <b-col cols="4" class="my-1">
              <blog-category-picker
                @getBlogCategory="setBlogCategory"
              ></blog-category-picker>
            </b-col>
            <b-col cols="2">
              <b-form-group
                label-for="hasComments"
                label="درج نظر برای نوشته"
                class="mt-2"
              >
                <div class="d-flex align-items-center">
                  <span style="margin-left: 8px"> خیر </span>
                  <b-form-checkbox
                    switch
                    v-model="blogDetails.allowComments"
                  ></b-form-checkbox>
                  بله
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group label="در آخرین اخبار باشد" class="mt-2">
                <div class="d-flex align-items-center">
                  <span style="margin-left: 8px"> خیر </span>
                  <b-form-checkbox
                    switch
                    v-model="blogDetails.isNews"
                  ></b-form-checkbox>
                  بله
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form @submit.prevent>
                <b-form-group label="خلاصه نوشته" label-for="summary">
                  <b-form-textarea
                    placeholder="کارگزاری بورس ستاره جنوب ارایه دهنده انواع..."
                    id="summary"
                    :state="summaryState"
                    v-model="blogDetails.summary"
                  ></b-form-textarea>
                  <b-form-invalid-feedback>
                    <div
                      class="w-100 d-flex align-items-center justify-content-between"
                    >
                      <p>طول خلاصه نباید بیشتر از 400 کاراکتر باشد.</p>
                      <template
                        v-if="
                          blogDetails.summary && blogDetails.summary.length > 0
                        "
                      >
                        {{ blogDetails.summary.length }}/400
                      </template>
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-form>
            </b-col>
            <b-col cols="12">
              <small>محتوای نوشته</small>
              <quillEditor
                ref="quillEditor"
                class="mt-1"
                v-model="blogDetails.description"
                :options="editorOption"
              >
                <div id="toolbar" slot="toolbar" class="ql-toolbar ql-snow">
                  <quill-toolbar></quill-toolbar>
                </div>
              </quillEditor>
            </b-col>
            <b-col cols="6" class="my-1">
              <b-form @submit.prevent>
                <b-form-group label="کاور نوشته" label-for="imageFile">
                  <b-form-file
                    id="imageFile"
                    v-model="blogImage"
                    accept="image/*"
                    @input="convertImageFileToBase64"
                  ></b-form-file>
                </b-form-group>
              </b-form>
            </b-col>
            <b-col cols="6" class="my-1">
              <div v-if="blogImage">
                <p>پیشنمایش تصویر نوشته</p>
                <div class="position-relative">
                  <img
                    style="width: 100%; max-height: 20rem; object-fit: contain"
                    :src="imagePreview"
                    alt=""
                  />
                  <feather-icon
                    icon="Trash2Icon"
                    size="25"
                    class="text-danger position-absolute"
                    style="left: 1rem; top: 1rem; cursor: pointer"
                    @click="deletePreviewImage"
                  />
                </div>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  :disabled="isCreateButtonDisabled"
                  type="button"
                  variant="success"
                  @click="sendBlogCreateRequest"
                >
                  ایجاد نوشته
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="12">
        <b-card>
          <h1 class="mb-3">انتخاب زبان نوشته</h1>
          <language-picker
            @getLanguage="setLanguage"
            :placeholder="`لطفا ابتدا زبان نوشته را انتخاب کنید`"
          ></language-picker>
          <div class="w-100 mt-3 d-flex align-items-center justify-content-end">
            <b-button
              @click="setLanguageIdOnRoute"
              variant="success"
              :disabled="blogDetails.languageId ? false : true"
            >
              ثبت زبان
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <library-image-picker @insertImage="insertImage"></library-image-picker>
  </b-overlay>
</template>
<script>
import BlogCategoryPicker from "@/views/components/Blog/BlogCategoryPicker.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { CreateABlog } from "@/libs/Api/Blog";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import QuillToolbar from "@/views/components/utils/QuillToolbar.vue";
import LibraryImagePicker from "@/views/components/utils/LibraryImagePicker.vue";
import LanguagePicker from "@/views/components/utils/LanguagePicker.vue";
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BFormInvalidFeedback,
  BInputGroupAppend,
  BFormFile,
  BFormSelect,
  BFormTextarea,
  BButton,
  BPagination,
  BOverlay,
} from "bootstrap-vue";

export default {
  title: "ایجاد نوشته جدید",
  data() {
    return {
      isLoading: false,
      blogDetails: {
        allowComments: false,
        isDeleted: false,
        blogId: 0,
        description: null,
        summary: null,
        languageId: null,
        mainId: "",
        createDate: new Date(Date.now()),
        title: null,
        status: 1,
        blogCategoryId: null,
        userId: 0,
        seoTitle: null,
        seoDescription: null,
        seourl: null,
        imageFile: null,
        likeCount: 0,
        viewsCount: 0,
        rate: 0,
      },
      blogImage: null,
      blogStatusEnums: [
        {
          text: "انتشار",
          value: 1,
        },
        {
          text: "آرشیو",
          value: 2,
        },
      ],
      editorOption: {
        modules: {
          toolbar: {
            container: "#toolbar",
            handlers: {
              image: this.imageMethod,
            },
          },
        },
        // Some Quill options...
        theme: "snow",
      },
    };
  },
  methods: {
    setLanguageIdOnRoute() {
      this.$router.push(`/pages/posts/create/${this.blogDetails.languageId}`);
    },
    setLanguage(language) {
      this.blogDetails.languageId = language.languageId;
    },
    imageMethod() {
      this.$bvModal.show("library-image-picker-modal");
    },
    insertImage(imageUrl) {
      this.$refs.quillEditor.quill.insertEmbed(
        this.$refs.quillEditor.quill.getSelection()?.index
          ? this.$refs.quillEditor.quill.getSelection().index
          : 1,
        "image",
        imageUrl
      );
    },
    async sendBlogCreateRequest() {
      try {
        if (this.$route.params.mainId) {
          this.blogDetails.mainId = this.$route.params.mainId;
        }
        this.blogDetails.languageId = this.$route.params.langId;
        this.isLoading = true;
        let _this = this;
        let createABlog = new CreateABlog(_this);
        createABlog.setRequestParamDataObj(_this.blogDetails);
        await createABlog.fetch((response) => {
          if (response.isSuccess) {
            _this.blogDetails = {
              isDeleted: false,
              blogId: 0,
              description: null,
              summary: null,
              createDate: new Date(Date.now()),
              title: null,
              status: 1,
              blogCategoryId: null,
              userId: 0,
              seoTitle: null,
              seoDescription: null,
              seourl: null,
              imageFile: null,
              likeCount: 0,
              viewsCount: 0,
              rate: 0,
            };
            _this.blogImage = null;
            _this.$toast({
              component: ToastificationContent,
              props: {
                title: "نوشته با موفقیت ایجاد شد",
                icon: "BookOpenIcon",
                variant: "success",
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    deletePreviewImage() {
      this.blogImage = null;
      this.blogDetails.imageFile = null;
    },
    convertImageFileToBase64() {
      const reader = new FileReader();
      reader.readAsDataURL(this.blogImage);
      reader.onload = () => {
        this.blogDetails.imageFile = reader.result.split(",")[1];
      };
    },
    setBlogCategory(categoryId) {
      this.blogDetails.blogCategoryId = categoryId;
    },
  },
  computed: {
    titleState() {
      if (this.blogDetails.title) {
        if (this.blogDetails.title.length > 200) {
          return false;
        } else if (this.blogDetails.title.length < 200) {
          return true;
        }
      } else {
        return null;
      }
    },
    summaryState() {
      if (this.blogDetails.summary) {
        if (this.blogDetails.summary.length > 400) {
          return false;
        } else if (this.blogDetails.summary.length < 400) {
          return true;
        }
      } else {
        return null;
      }
    },
    isNewPage() {
      if (this.$route.params.mainId) {
        return false;
      }
      return true;
    },
    mainPageLink() {
      if (!this.isNewPage) {
        return `https://BourseSJ.oregonserver.com/blogs/${this.$route.query.link}`;
      }
    },
    isCreateButtonDisabled() {
      if (this.summaryState && this.titleState) {
        return false;
      }
      return true;
    },
    imagePreview() {
      if (this.blogImage) {
        return URL.createObjectURL(this.blogImage);
      }
    },
  },
  components: {
    BlogCategoryPicker,
    quillEditor,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BFormFile,
    BFormInvalidFeedback,
    BFormSelect,
    BFormTextarea,
    BButton,
    BPagination,
    BOverlay,
    QuillToolbar,
    LibraryImagePicker,
    LanguagePicker,
  },
};
</script>
